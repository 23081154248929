import Vue from "vue";
import { GridPlugin, Toolbar, Page, Filter, Sort, CommandColumn } from "@syncfusion/ej2-vue-grids";
import { DialogUtility } from "@syncfusion/ej2-popups";
import { CustomUrlAdaptor } from "@/syncfusion/CustomUrlAdaptor";
import { DataManager } from "@syncfusion/ej2-data";
import SpinnerMixin from "@/mixins/spinner";
import titleMixin from "@/mixins/title";
import gridMixin from "@/mixins/syncfusion/grid";
import LocaleMixin from "@/mixins/locale";
import { formatDateTime } from "@/helpers/dateTimeHelper";
import AppointmentsPaymentDialog from "@/components/Templates/Customer/AppointmentsPaymentDialog.vue";
import { mapGetters } from "vuex";
Vue.use(GridPlugin);
export default Vue.extend({
    mixins: [gridMixin, titleMixin, LocaleMixin, SpinnerMixin],
    components: {
        "appointments-payment-dialog": AppointmentsPaymentDialog
    },
    data() {
        return {
            customer: {},
            title: this.$t('appointments.title'),
            data: new DataManager({
                url: process.env.VUE_APP_API_BASE_URL +
                    `customers/${this.$route.params.id}/appointments/grid-data`,
                //insertUrl: process.env.VUE_APP_API_BASE_URL + "customers/grid-create",
                //updateUrl: process.env.VUE_APP_API_BASE_URL + "customers/grid-edit",
                //removeUrl: process.env.VUE_APP_API_BASE_URL + "customers/grid-delete",
                adaptor: new CustomUrlAdaptor()
            }),
            toolbarOptions: [
                {
                    text: this.$t('appointments.updatePayments'),
                    prefixIcon: "e-payment",
                    id: "SetPayment"
                }
            ],
            commands: [
                {
                    type: "SetPayment",
                    title: this.$t("payments.update.submit"),
                    buttonOption: { cssClass: "e-flat", iconCss: "e-payment e-icons" }
                },
                {
                    type: "RemovePayment",
                    title: this.$t("payments.delete.submit"),
                    buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" }
                }
            ],
            sortOptions: {
                columns: [{ field: "StartDateTime", direction: "Descending" }]
            },
            filterOptions: { type: "Menu" },
            pageSettings: { pageSize: 10 },
            selectedAppointments: []
        };
    },
    provide: {
        grid: [Toolbar, Page, Filter, Sort, CommandColumn]
    },
    computed: {
        ...mapGetters({
            hasMultipleOffices: "account/hasMultipleOffices"
        })
    },
    methods: {
        toolbarClick(args) {
            if (args.item.id == "SetPayment") {
                const grid = this.$refs["appointment-grid"];
                this.selectedAppointments = grid.getSelectedRecords();
                if (this.selectedAppointments.length > 0) {
                    this.$refs.appointmentsPaymentDialog.show();
                }
                return;
            }
        },
        async commandClick(args) {
            if (args.commandColumn.type === "SetPayment") {
                this.selectedAppointments = [args.rowData];
                this.$refs.appointmentsPaymentDialog.show();
                return;
            }
            if (args.commandColumn.type === "RemovePayment") {
                const dialog = DialogUtility.confirm({
                    title: "",
                    content: this.$t("payments.delete.confirm", {
                        date: this.formatDateTime(args.rowData.StartDateTime)
                    }),
                    showCloseIcon: false,
                    closeOnEscape: true,
                    okButton: {
                        text: "Ok",
                        click: async () => {
                            await this.$store.dispatch("customer/deleteAppointmentPayment", {
                                id: args.rowData.CustomerId,
                                appointmentId: args.rowData.AppointmentId
                            });
                            dialog.close();
                            this.$toast.showSuccessToast(this.$t("payments.delete.toastSuccessTitle"), this.$t("payments.delete.toastSuccessContent", {
                                name: args.rowData.CustomerFullName
                            }));
                            this.refreshGrid();
                        }
                    },
                    cancelButton: {
                        text: this.$t('shared.cancel'),
                        click: () => {
                            dialog.close();
                            this.refreshGrid();
                        }
                    },
                    position: {
                        X: "center",
                        Y: "center"
                    }
                });
                return;
            }
        },
        onAppointmentsPaymentDialogClose() {
            this.refreshGrid();
        },
        formatDateTime(value) {
            return formatDateTime(this.appLocale, value);
        },
        refreshGrid() {
            const grid = this.$refs["appointment-grid"];
            grid.refresh();
        }
    },
    async mounted() {
        this.showSpinner();
        try {
            this.customer = await this.$store.dispatch("customer/get", {
                id: this.$route.params.id
            });
            this.setTitle(`${this.$t('shared.appointmentsOfCustomer')} ${this.customer.name} ${this.customer.surname}`);
            this.hideSpinner();
        }
        catch (errors) {
            this.hideSpinner();
            this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                error: errors[0].message
            }));
            await this.$router.push("/employees");
        }
    }
});
